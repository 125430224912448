import ActionType from '../action/type';
import Cookies from 'js-cookie';

let question = Cookies.get("question") ? Cookies.get("question") : "show me the distribution"

const initialState = {
    question
}

export default (state = initialState, action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case ActionType.UPDATE_QUESTION:
            newState.question = action.question
            Cookies.set("question", action.question)
            return newState;
        default:
            break;
    }
    return newState;
}